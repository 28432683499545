import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const IconsServices = memo(function IconsServices({
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Services row dial={2} wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  background: #53a78b;
  margin: 0 auto 6rem;
  padding: 5.6875rem 1.875rem 6.875rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 0 auto 3.75rem;
    padding: 5.6875rem 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  margin: 0 auto 1.25rem;

  @media (max-width: 1023px) {
    padding: 0 50px;
    font-size: 25px;
  }
`

const Services = styled(FlexBox)`
  max-width: 1136px;
  margin: auto;
  row-gap: 0 6.25rem;

  @media (max-width: 480px) {
    justify-content: flex-start;
  }
`
