import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { Separator } from 'app/components/Common/Separator'
import { Hero } from 'app/components/Hero'
import { IconsServices } from 'app/components/IconsServices'
import { RoomsList } from 'app/components/RoomsList'
import { SEO } from 'app/components/SEO'
import { SimpleIntro } from 'app/components/SimpleIntro'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id?: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function RoomsPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.simpleIntroProps ? (
        <SimpleIntro {...context.simpleIntroProps} />
      ) : null}
      {context.iconsServicesProps ? (
        <IconsServices {...context.iconsServicesProps} />
      ) : null}
      <Separator />
      {context.roomsListProps ? (
        <RoomsList {...context.roomsListProps} />
      ) : null}
      <Separator />
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
