import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25, triggerOnce: true })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? <Icon src={icon} alt={label} width="30" height="30" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  width: 16.6%;
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    width: 25%;
  }

  @media (max-width: 767px) {
    width: 33.333%;
  }

  @media (max-width: 480px) {
    width: 50% !important;
  }
`

const Icon = styled.img`
  width: auto;
  max-width: 3.65rem;
  height: 3.65rem;
  margin-bottom: 1.25rem;

  @media (max-width: 767px) {
    height: 40px;
    max-width: 40px;
  }
`

const Label = styled.div`
  max-width: 11.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  text-transform: uppercase;
  margin: auto;
`
